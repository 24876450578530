@import 'mixins.scss';

.subbar {
  background: transparent;
  min-height: rem(48);
  border-bottom: 1px solid $border;
  padding: rem(10) rem(30);
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}

.divider {
  width: 1px;
  background-color: $border;
  align-self: stretch;
  flex-grow: 0;
  flex-shrink: 0;
}

.breadcrumbs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  font-size: rem(15);
}

.breadcrumb {
  position: relative;
  margin-right: rem(20);

  &::after {
    content: '/';
    position: absolute;
    right: rem(-12);
    bottom: 0;
    opacity: 0.5;
  }

  &:last-child {
    margin-right: 0;

    &::after {
      display: none;
    }
  }
}

.breadcrumbLink {
  display: block;

  &__current {
    color: $black;
    font-weight: 700;
    pointer-events: none;
  }
}

.amount {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.amountText {
  font-size: rem(12);
  color: $gray-4;
  flex-shrink: 0;
  margin-right: rem(15);
  margin-bottom: 0;
  text-align: right;
  text-transform: uppercase;
}

.amountValue {
  color: $black;
  font-size: rem(16);
  display: block;
  font-weight: 700;
  line-height: 1;
}

.amountGraph {
  flex-shrink: 0;
  display: flex;
  align-items: flex-end;
}

.amountGraphItem {
  margin-right: rem(6);
  width: 4px;
  border-radius: 3px;
  background-color: $blue;

  &:last-child {
    margin-right: 0;
  }
}

// dark theme
[data-vb-theme='dark'] {
  .subbar {
    background: $dark-gray-6;
    border-color: $dark-gray-4;
  }

  .divider {
    background-color: $dark-gray-4;
  }

  .amountText {
    color: $dark-gray-1;
  }

  .amountValue {
    color: $white;
  }
}
